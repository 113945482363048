




































import { reactive, ref, defineComponent, SetupContext } from '@vue/composition-api'
import axios from 'axios'
export default defineComponent({
  name: 'PasswordForgot',
  setup(_, ctx: SetupContext) {
    const inputs = reactive({
      mail: '',
    })
    const rules = reactive({
      email: [
        (v: string) => !!v || 'メールアドレスは必須項目です',
        (v: string) => /.+@.+\..+/.test(v) || 'メールアドレスの形式が正しくありません',
      ],
    })
    const valid = ref(false)
    const requestMessage = reactive({
      message: '',
      visiable: false,
      type: 'error',
    })
    const loading = ref(false)
    const form = ref(null)
    const submit = async () => {
      loading.value = true
      requestMessage.visiable = false
      const { uri } = ctx.root.$appConfig.endpoints.passwordForgot
      const data = await axios
        .post(uri, inputs)
        .then(() => true)
        .catch((err) => {
          requestMessage.type = 'error'
          if (err.response.status == 400) {
            requestMessage.message = 'メールアドレスが登録されていません'
          } else {
            requestMessage.message = 'サーバーエラーが発生しました'
            console.error(err.response)
          }
          return false
        })
        .finally(() => {
          loading.value = false
        })
      if (data) {
        requestMessage.message = 'パスワード再発行用のリンクを送信しました'
        requestMessage.type = 'success'
      }
      requestMessage.visiable = true
    }
    return {
      inputs,
      rules,
      valid,
      loading,
      submit,
      form,
      requestMessage,
    }
  },
})
